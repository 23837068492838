<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Main Section -->
      <div class="col-12 col-md-7 col-lg-8 col-xl-8">
        <!-- Toggle and Classroom Selection Row -->
        <div class="row">

          <!-- Image/Video Toggle -->
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3
            order-3 order-md-3 order-lg-2 order-xl-1">
            <div class="left-header mt-2 mb-2">
              <input class="toggle toggle-left"
                     id="toggle-images"
                     name="toggle"
                     type="radio"
                     value="image"
                     checked
                     v-model="contentTab">
              <label class="toggle-label img-tab"
                     for="toggle-images">
                Images
              </label>

              <input class="toggle toggle-right"
                     id="toggle-videos"
                     name="toggle"
                     type="radio"
                     value="video"
                     v-model="contentTab">
              <label class="toggle-label vid-tab"
                     for="toggle-videos">
                Videos
              </label>
            </div>
          </div>

          <!-- Classroom Name -->
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6
            order-1 order-md-1 order-lg-1 order-xl-2">
            <p class="classroom-name mb-0"
               :title="classroom.classroom_name">
              {{ classroom.classroom_name | truncate(30) }}
            </p>
          </div>

          <!-- Classroom Selection, QR Code -->
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3
            order-2 order-md-2 order-lg-3 order-xl-3">
            <div class="right-header">
              <b-form-select class="classroom-selection"
                            :options="classrooms"
                            v-model="currentClassroom">
              </b-form-select>

              <img class="qr-code-icon"
                    :class="{ 'qr-disabled' : !classroom.show_qr_code }"
                    src="../../assets/images/qr-code.png"
                    title="Show QR Code"
                    alt="QR Code Icon"
                    @click="showQRCode"
                    v-if="isMyClassroom" />

              <span class="send-invite ml-1"
                    title="Send Invite"
                    @click="showSendInviteModal"
                    v-if="false">
                <i class="fas fa-user-plus"></i>
              </span>
            </div>
          </div>
        </div>

        <!-- Image/Video Gallery Row -->
        <div class="row">
          <div class="col">
            <transition name="component-fade" mode="out-in">
              <image-gallery :colImage="colImage"
                             :border="true"
                             :images="contents"
                             :userImage="true"
                             v-if="contentTab === 'image'">
              </image-gallery>

              <video-gallery :colVideo="colVideo"
                             :border="true"
                             :videos="contents"
                             :userImage="true"
                             v-if="contentTab === 'video'">
              </video-gallery>
            </transition>
          </div>
        </div>

        <!-- Load More -->
        <div class="row"
             v-if="contentsCurrentPage !== contentsLastPage &&
               contentsLastPage > 0">
          <div class="col text-center">
            <b-button class="btn btn-h3-small btn-h3-blue mb-3 mt-3"
                      type="button"
                      @click="loadMoreContent">
              Load More
            </b-button>
          </div>
        </div>
      </div>

      <!-- Right Section -->
      <div class="col-12 col-md-5 col-lg-4 col-xl-4">
        <div>
          <!-- Approval Section -->
          <pending-approval v-if=" isMyClassroom && (user.user_type === 3 ||
                              user.user_type === 4)"
                            @update-images="updateImages"
                            @update-videos="updateVideos"
                            @update-students="updateStudents">
          </pending-approval>

          <!-- Students -->
          <student-list :students="students"
                        @filter-content="filterContent"
                        @clear-filter="clearFilter"
                        @removed-student="updateClassroom"
                        :classroomId="classroom.classroom_id"
                        :isMyClassroom="isMyClassroom">
            <div class="row" v-if="studentsCurrentPage !== studentsLastPage">
              <div class="col text-center">
                <b-button class="btn btn-h3-small btn-h3-blue mb-2 mt-2"
                          type="button"
                          @click="loadMoreStudent">
                  Load More
                </b-button>
              </div>
            </div>
          </student-list>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  const ImageGallery = () =>
  import('@/components/user/common/ImageGallery.vue');
  const VideoGallery = () =>
  import('@/components/user/common/VideoGallery.vue');
  const PendingApproval = () =>
  import('@/components/user/classroom/PendingApproval.vue');
  const StudentList = () =>
  import('@/components/user/classroom/StudentList.vue');
  const QrCode = () => import('@/components/user/classroom/QRCode.vue');
  const SendClassroomInvite = () =>
  import('@/components/modals/SendClassroomInvite.vue');

  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    name       : 'Classroom',
    components : {
      ImageGallery,
      VideoGallery,
      PendingApproval,
      StudentList,
      // eslint-disable-next-line vue/no-unused-components
      QrCode,
      // eslint-disable-next-line vue/no-unused-components
      SendClassroomInvite,
    },
    data() {
      return {
        user             : this.$store.getters['user/user'],
        currentClassroom : null,
        isMyClassroom    : false,
        contentTab       : 'image',
        classrooms       : [{
          value    : null,
          text     : 'Classrooms',
          disabled : true,
        }],
        classroom : null,

        students            : [],
        studentsPerPage     : 8,
        studentsCurrentPage : 0,
        studentsLastPage    : 0,

        contents            : [],
        contentsPerPage     : 8,
        contentsCurrentPage : 0,
        contentsLastPage    : 0,
        studentFilter       : {},
        tabOptions          : [ {
          text  : 'Images',
          value : 'image',
        }, {
          text  : 'Videos',
          value : 'video',
        } ],
      }
    },
    computed : {
      colImage() {
        return this.getPortion(3)
      },
      colVideo() {
        return this.getPortion(2)
      },
    },
    watch : {
      contentTab : function(value) {
        if (value)
          this.getClassroomContent(0);
      },
      currentClassroom : function(value) {
        this.$router.push({
          name   : 'classroom',
          params : {
            classroomId : value,
          },
        });
        this.getClassroom();
        this.updateClassroom();
        this.checkIfMyClassroom(value);
      },
    },
    mounted() {
      this.currentClassroom = this.$route.params.classroomId;
      this.getClassroom();

      // Check if current educator created classroom
      if(this.user.user_type === 3 || this.user.user_type === 4)
        this.checkIfMyClassroom(this.$route.params.classroomId);

      // Load Classroom List
      this.classrooms = [{
        value    : null,
        text     : 'Classrooms',
        disabled : true,
      }];
      this.getClassrooms();

      // Load Classroom Content
      this.updateClassroom();
    },
    methods : {

      /**
       * Get Classroom Data
       */
      getClassroom() {
        this.$http.get('/api/classroom', {
          params : {
            classroomId : this.currentClassroom,
          },
        }).then((response) => {
          this.classroom = response.data;
        });
      },

      /**
       * Update School Classroom
       */
      updateClassroom() {
        // Reset Classroom Content variables
        this.contents = [];
        this.contentsCurrentPage = null;
        this.contentsLastPage = null;

        // Reset Classroom Student variables
        this.students = [];
        this.studentsCurrentPage = null;
        this.studentsLastPage = null;

        // Load Classroom Students and Contents
        this.getClassroomStudents(0);
        this.getClassroomContent(0);
      },

      /**
       * Get School Classrooms for select options
       */
      getClassrooms() {
        if (this.user.user_type === 3 || this.user.user_type === 4) {
          // Educator and Champion
          this.$http.get('api/school/classrooms', {
            params : {
              schoolId : this.user.school_id,
              page     : 1,
              perPage  : 500,
            },
          }).then(res => {
            const classrooms = res.data.data;
            if (classrooms)
              this.formatClassroomList(classrooms);
          });
        } else {
          // Student
          if (this.user.classroom)
            this.formatClassroomList(this.user.classroom);
        }
      },

      /**
       * Format Classroom List
       * @param classrooms
       */
      formatClassroomList(classrooms) {
        // Format classroom list for select's option
        classrooms.forEach(element => {
          if (this.user.user_type === 3 || this.user.user_type === 4) {
            if (this.user.user_id === element.educator_id) {
              this.classrooms.push({
                value : element.classroom_id,
                text  : element.classroom_name,
              });
            }
          } else {
            this.classrooms.push({
              value : element.classroom_id,
              text  : this.$options.filters
                .truncate(element.classroom_name, 30),
            });
          }
        });
      },

      /**
       * Get Students per Classroom
       * @param currentPage Pagination's current page
       */
      getClassroomStudents(currentPage = this.studentsCurrentPage) {
        this.$http.get('api/classroom/classmates', {
          params : {
            classroomId : this.$route.params.classroomId,
            page        : currentPage + 1,
            perPage     : this.studentsPerPage,
          },
        }).then((response) => {
          this.studentsCurrentPage = response.data.currentPage;
          this.studentsLastPage = response.data.lastPage;

          if (this.studentsCurrentPage > 1) {
            const arr1 = this.students;
            const arr2 = response.data.data;

            this.students = arr1.concat(arr2);
          } else
            this.students = response.data.data;
        }).catch(() => {
          this.students = [];
        });
      },

      /**
       * Check if currently logged in educator created the page.
       * @param currentPage Pagination's current page
       */
      checkIfMyClassroom(classroomId){
        this.$http.get('api/isMyClassroom', {
          params : {
            classroomId : classroomId,
          },
        }).then((response) => {
          this.isMyClassroom = response.data;
        });
      },

      /**
       * Load more students
       */
      loadMoreStudent() {
        this.getClassroomStudents();
      },

      /**
       * Get all approved classroom content
       * @param {integer} currentPage Pagination's current page
       */
      getClassroomContent(currentPage = this.contentsCurrentPage) {
        this.$http.get('api/classroom/content', {
          params : {
            classroomId : this.$route.params.classroomId,
            perPage     : this.contentsPerPage,
            page        : currentPage + 1,
            sort        : 'id|asc',
            type        : this.contentTab,
            userId      : this.studentFilter.user_id,
          },
        }).then((response) => {
          this.contentsCurrentPage = response.data.currentPage;
          this.contentsLastPage = response.data.lastPage;

          if (this.contentsCurrentPage > 1) {
            const arr1 = this.contents;
            const arr2 = response.data.data;

            this.contents = arr1.concat(arr2);
          } else this.contents = response.data.data;
        }).catch(() => {
          this.contents = [];
        });
      },

      /**
       * Load more classroom content
       */
      loadMoreContent() {
        this.getClassroomContent();
      },

      /**
       * Update image function emitted from PendingApproval.vue
       */
      updateImages() {
        this.contentTab = 'image';
        this.getClassroomContent(0);
      },

      /**
       * Update video function emitted from PendingApproval.vue
       */
      updateVideos() {
        this.contentTab = 'video';
        this.getClassroomContent(0);
      },

      /**
       * Update student function emitted from PendingApproval.vue
       */
      updateStudents() {
        this.getClassroomStudents(0);
      },

      /**
       * Opens Classroom QR Code modal
       */
      showQRCode() {
        if (this.classroom.show_qr_code) {
          const url = process.env.VUE_APP_BASE_URL + '/classroom/' +
            this.classroom.classroom_id + '/qr-code';

          window.open(
            url,
            this.classroom.classroom_name,
            "width=" + screen.availWidth + ", height=" + screen.availHeight,
          );
        } else {
          this.$notify({
            group : 'notif',
            type  : 'warn',
            title : 'Classroom',
            text  : `Show QR Code is disabled. Enable it by editing the
              Classroom.`,
          });
        }
      },

      /**
       * Opens Send Invite Modal
       */
      showSendInviteModal() {
        this.$modal.show(SendClassroomInvite, {
          classroomId : this.currentClassroom,
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },

      /**
       * Filter Classroom Content
       * @param student
       */
      filterContent(student) {
        this.studentFilter = student;
        this.getClassroomContent(0);
      },

      /**
       * Clear Filter Classroom Content
       */
      clearFilter() {
        this.studentFilter = {};
        this.getClassroomContent(0);
      },
    },
    mixins : [
      BreakpointMixin,
      FilterMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/classroom";
</style>
